import React from 'react';
import { IconProps } from '../IconProps';
const SvgFaceToFace = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M12.31 11.614c1.035 0 1.874-.867 1.874-1.936s-.839-1.935-1.873-1.935c-1.035 0-1.873.866-1.873 1.935 0 1.07.838 1.936 1.873 1.936zM3.748 11.614c1.035 0 1.873-.867 1.873-1.936s-.838-1.935-1.873-1.935c-1.034 0-1.873.866-1.873 1.935 0 1.07.839 1.936 1.873 1.936zM15.997 15.366l-1.19.312c-1.64.43-3.359.43-4.999 0l-1.19-.312c.308-1.818 1.842-3.199 3.69-3.199 1.847 0 3.38 1.381 3.69 3.199zM7.38 15.366l-1.191.312c-1.64.43-3.358.43-4.998 0L0 15.366c.308-1.818 1.843-3.199 3.69-3.199s3.382 1.381 3.69 3.199z"
      fill="#0D0300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.89 1.383C5.89.619 6.49 0 7.23 0h5.352c.739 0 1.338.619 1.338 1.383V4.59c0 .764-.6 1.383-1.338 1.383H8.329L5.891 7.679V1.383z"
      fill="#0D0300"
    />
  </svg>
);
export default SvgFaceToFace;
