import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { BodyText } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { rem } from 'polished';

import { Phone } from 'icons';
import { Link, useLocation } from 'react-router-dom';
import { css, styled } from 'styles';
import { medium, small } from 'utils';
import { isMSTeams } from 'utils/MSTeams';
import { Events } from 'App/Tracking/Events';
import { tracking } from 'App/Tracking';
import { useOrganisationEntitlements } from 'Shared/Entitlement';
import RoutePath from '../App/RoutePath';

export const HelpContainer = styled(Link)`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  margin-left: auto;
  margin-right: ${rem(16)};
  margin-bottom: ${rem(4)};
  padding: ${rem(6)} ${rem(8)};
  border-radius: ${({ theme }) => rem(theme.button.borderRadius.default)};

  :hover {
    background-color: ${({ theme }) => theme.colors.background.skeleton};
  }

  ${medium(css`
    margin-right: ${rem(8)};
  `)}
`;

export const HelpIcon = styled(Phone).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  height: 12,
  width: 12,
  ['aria-hidden']: true,
}))`
  margin-right: 6px;
`;

export const HelpLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize14],
  color: theme.colors.text.primary,
}))`
  line-height: 1;
  white-space: nowrap;
`;

export const HiddenOnMobileAndTablet = styled.div`
  display: none;

  ${small(css`
    display: flex;
    flex: 1;
  `)}
`;

const HelpLink = ({ path }: { path: RoutePath }) => {
  const { t } = useTranslation<Namespace<'help'>>('help');
  const location = useLocation();
  const { entitlements } = useOrganisationEntitlements([
    { entitlement: 'unmindHelp' },
  ]);

  if (isMSTeams()) {
    return null;
  }

  const trackClientside = (
    eventName: Events,
    properties: Record<string, unknown>,
  ) => {
    tracking.track(eventName, properties);
  };

  return (
    <HiddenOnMobileAndTablet>
      <HelpContainer
        to={path}
        onClick={() =>
          trackClientside('help_immediate_support_clicked', {
            origin: location.pathname,
            unmindHelpEnabled: entitlements.unmindHelp,
          })
        }
      >
        <HelpIcon />
        <HelpLabel>{t('immediate_support')}</HelpLabel>
      </HelpContainer>
    </HiddenOnMobileAndTablet>
  );
};

export default HelpLink;
