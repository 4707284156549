import {
  BodyText,
  HeadingText,
  Button,
} from '@unmind/design-system-components-web';
import { IndicatorError } from 'icons';
import baseButtonStyles from 'Shared/Button/BaseStyles';
import { styled } from 'styles';

export const ChatClearErrorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  justify-content: center;
  padding-top: 8px;
  gap: 8px;
`;

export const ChatClearErrorIcon = styled(IndicatorError).attrs(({ theme }) => ({
  primaryColor: theme.colors.background.primary,
  secondaryColor: theme.colors.background.danger,
  height: '18',
  width: '18',
}))`
  aria-hidden: true,
  align-self: center;
  justify-self: center;
`;

export const ChatClearErrorText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  color: ${({ theme }) => theme.colors.background.danger};
  flex-shrink: 1;
`;

export const ChatClearBodyText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  text-align: center;
`;

export const ChatClearTitleText = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.medium,
  level: 1,
}))`
  text-align: center;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const ChatClearButtonsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.sheet.background};
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`;

export const ChatClearContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.sheet.background};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;
  padding: 16px;
  align-items: center;
  justify-content: center;
`;

export const ClearButton = styled(Button)`
  ${baseButtonStyles}
  background-color: ${({ theme }) => theme.colors.background.danger};
  border-color: ${({ theme }) => theme.colors.background.danger};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.background.danger};
  }
`;

export const ClearButtonText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.button.primary.danger.default.textColor,
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  text-align: center;
`;

export const CancelButton = styled(Button)`
  ${baseButtonStyles}
  background-color: ${({ theme }) => theme.colors.sheet.background};
  color: ${({ theme }) => theme.colors.text.secondary};
  border-width: 0;
`;
