import { useQuery } from '@apollo/client';
import RoutePath from 'App/RoutePath';
import { HELP_FOLDER_QUERY } from 'Track/Wellbeing/Score/CrisisHelpDisclaimer';
import { HelpFolderQuery } from 'Track/Wellbeing/Score/__generated__/HelpFolderQuery';
import { Namespace } from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'Shared/Typography';
import { useOrganisationEntitlements } from 'Shared/Entitlement';
import { LimitsHeader } from 'Talk/components/LimitsHeader';
import {
  MatchingCTA,
  PractitionerMatches,
  useMatches,
} from '../../components/Matching';
import {
  BodyCopySmall,
  HeaderSlice,
  PageSubTitle,
  PageTitle,
} from '../common/styles';
import {
  CollapsingAccordionContainer,
  LandingPageBody,
  StyledAccordion,
  MoreAboutTalkHeader,
  AccordionItemHeader,
  HeaderTitleSlice,
} from './styles';

export const LandingPage = () => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const { entitlements, loading: entitlementsLoading } =
    useOrganisationEntitlements([{ entitlement: 'aiEnabled' }]);
  const aiMatchingEnabled = entitlements.aiEnabled;
  const { data: helpFolderData } = useQuery<HelpFolderQuery>(HELP_FOLDER_QUERY);
  const {
    userHasPractitionerMatches,
    practitionerMatches,
    practitionerMatchesLoading,
    practitionerMatchesError,
  } = useMatches();

  const urgentHelpCategory = helpFolderData?.helpContentRoot?.items?.find(
    item => item?.folderType === 'Urgent',
  );

  if (!aiMatchingEnabled && !entitlementsLoading) {
    // Redirect to Talk Browse if AI matching is not enabled
    return <Redirect to={RoutePath.TalkBrowse} />;
  }

  const MatchingUI = () => {
    // Loading UI is handled within the PractitionerMatches component
    if (practitionerMatchesLoading) {
      return <></>;
    }

    if (practitionerMatchesError) {
      return <Redirect to={RoutePath.TalkBrowse} />;
    }

    return userHasPractitionerMatches ? (
      <PractitionerMatches
        matches={practitionerMatches}
        trackingSource="home_match"
      />
    ) : (
      <MatchingCTA />
    );
  };

  return (
    <>
      <HeaderTitleSlice>
        <PageTitle>{t('title')}</PageTitle>
        <LimitsHeader />
      </HeaderTitleSlice>
      <HeaderSlice>
        <PageSubTitle>{t('subtitle')}</PageSubTitle>
      </HeaderSlice>

      <LandingPageBody>
        <MatchingUI />
      </LandingPageBody>

      {!practitionerMatchesLoading && !userHasPractitionerMatches && (
        <CollapsingAccordionContainer>
          <MoreAboutTalkHeader>{t('about_talk.title')}</MoreAboutTalkHeader>
          <StyledAccordion
            items={[
              {
                header: (
                  <AccordionItemHeader>
                    {t('about_talk.for_me.title')}
                  </AccordionItemHeader>
                ),
                content: (
                  <BodyCopySmall>
                    <Trans
                      t={t}
                      i18nKey="about_talk.for_me.content"
                      components={{
                        styled_link: (
                          <ExternalLink
                            href={t('about_talk.for_me.url')}
                            rel="noopener noreferrer"
                            target="_blank"
                            aria-label={t('about_talk.for_me.title')}
                          />
                        ),
                      }}
                    />
                  </BodyCopySmall>
                ),
              },
              {
                header: (
                  <AccordionItemHeader>
                    {t('about_talk.how_talk_works.title')}
                  </AccordionItemHeader>
                ),
                content: (
                  <>
                    <BodyCopySmall>
                      {t('about_talk.how_talk_works.body_1')}
                    </BodyCopySmall>
                    <BodyCopySmall>
                      {t('about_talk.how_talk_works.body_2')}
                    </BodyCopySmall>
                    <BodyCopySmall>
                      {t('about_talk.how_talk_works.body_3')}
                    </BodyCopySmall>
                    <BodyCopySmall>
                      {t('about_talk.how_talk_works.body_4')}
                    </BodyCopySmall>
                  </>
                ),
              },
              {
                header: (
                  <AccordionItemHeader>
                    {t('about_talk.contact_in_crisis.title')}
                  </AccordionItemHeader>
                ),
                content: (
                  <BodyCopySmall>
                    <Trans
                      t={t}
                      i18nKey="about_talk.contact_in_crisis.content"
                      components={{
                        help_link: (
                          <Link
                            to={{
                              pathname: `${RoutePath.Help}/${urgentHelpCategory?.id}`,
                            }}
                          />
                        ),
                      }}
                    />
                  </BodyCopySmall>
                ),
              },
            ]}
          />
        </CollapsingAccordionContainer>
      )}
    </>
  );
};
