import { compareAsc, isSameDay, isToday } from 'date-fns';
import { ConversationSession, Message } from '../types';

export const messagesToConversationSessions = (
  messages: Message[],
): ConversationSession[] => {
  const mappedSessions = messages.reduce(
    (acc: ConversationSession[], message: Message) => {
      const date = new Date(message.createdAt);

      // Check to see if dated session already created in accumulated result
      const existingSessionIndex = acc.findIndex(session =>
        isSameDay(session.date, date),
      );

      // If the date key exists on accumulated result then push new mapped message to it.
      if (existingSessionIndex > -1) {
        acc[existingSessionIndex].messages.push(message);
      } else {
        // If not create new session item for said date key
        acc.push({
          date: date,
          messages: [message],
        });
      }

      return acc;
    },
    [],
  );

  const todaysDate = new Date();

  // Check if there is a session for today and if not create one
  const todaysSession = mappedSessions.find(session => isToday(session.date));
  if (!todaysSession) {
    mappedSessions.push({
      date: todaysDate,
      messages: [],
    });
  }

  const mappedSessionsToLog = mappedSessions.sort((a, b) =>
    compareAsc(a.date, b.date),
  );

  return mappedSessionsToLog;
};
