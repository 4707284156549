import {
  medium,
  small,
  BodyText,
  HeadingText,
} from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { Phone } from 'icons';
import { rem } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { useSubdomainInfo } from 'LoggedOut/SignUp/useSubdomainInfo';
import { tracking } from 'App/Tracking';
import { getEapServices_helpEAPServices as EapService } from './hooks/__generated__/getEapServices';
import { HelpIcon } from './HelpIcons';

const TabMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(52)};

  ${small(css`
    flex-direction: row;
  `)};
`;

const TabList = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${rem(296)};
  margin-bottom: ${rem(40)};
  & > * + * {
    margin-top: ${rem(12)};
  }
  flex: 1;

  ${medium(css`
    flex: 0;
  `)};
`;

const Tab = styled.button<{ active: boolean }>`
  padding: ${rem(12)};
  background: ${({ active, theme }) =>
    active
      ? theme.colors.background.secondary
      : theme.colors.background.primary};
  border: none;
  border-radius: ${rem(12)};
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: ${({ theme }) => theme.colors.background.secondary};
  }
  gap: ${rem(8)};
`;

const TabTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize16],
  color: theme.colors.text.primary,
}))`
  line-height: ${rem(16)};
`;

const TabContent = styled.div`
  flex: 1;
  margin-left: 0;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: ${rem(12)};
  padding: ${rem(24)};

  ${small(css`
    margin-left: ${rem(32)};
  `)};
`;

const HelpContactLink = styled.a`
  display: flex;
  background: ${({ theme }) => theme.button.primary.default.backgroundColor};
  gap: ${rem(8)};
  padding: ${rem(12)} ${rem(20)};
  border-radius: ${rem(100)};
  width: fit-content;
  &:hover {
    background: ${({ theme }) =>
      theme.button.primary.default.hover.backgroundColor};
  }
`;

const HelpPhoneIcon = styled(Phone).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.inverse,
  height: 16,
  width: 16,
  ['aria-hidden']: true,
}))`
  align-self: center;
`;

const HelpContactText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.inverse,
}))``;

const ServiceDetailHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  padding-bottom: ${rem(24)};
`;

const ServiceDetailTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(24)};

  ${medium(css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `)};
`;

const ServiceDetailTitle = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.semiBold,
  level: 2,
}))`
  line-height: 100%;
  margin-bottom: ${rem(24)};

  ${medium(css`
    margin-bottom: 0;
  `)};
`;

const ServiceDetailSubtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
  color: theme.colors.text.secondary,
}))``;

const ServiceDetailDescription = styled(BodyText).attrs(({ theme }) => ({
  forwardedAs: 'div',
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  line-height: 140%;
  color: ${({ theme }) => theme.colors.text.primary};

  li:not(:last-child) {
    margin-bottom: ${rem(8)};
  }
`;

interface HelpServicesTabsProps {
  contactNumber?: string;
  eapServices: EapService[];
  countryCode: string;
}

/**
 * This is a custom vertical tab component that displays help services.
 *
 * Ideally, we would develop the Tabs component in the Shared folder
 * or create a Tabs component in the Design System that supports
 * a vertical or horizontal orientation. However, this was out of scope
 * for ENG-16210 and custom styling was also required.
 */
const HelpServicesTabs = ({
  contactNumber,
  eapServices,
  countryCode,
}: HelpServicesTabsProps) => {
  const { t: translate } = useTranslation('help');
  const hash = window.location.hash.substring(1); // Remove the '#' character

  // Set the initial active service with a valid URL hash or the first service in the list
  const initialService =
    eapServices.find(service => service.icon === hash) || eapServices[0];
  const [activeServiceId, setActiveServiceId] = useState<string>(
    initialService.id,
  );

  const { groupName } = useSubdomainInfo({
    subdomain: getSubdomainFromUrl(),
  });

  const activeService = useMemo(
    () =>
      eapServices.find(service => service.id === activeServiceId) ||
      eapServices[0],
    [activeServiceId, eapServices],
  );

  useEffect(() => {
    // Tracks the initial active service on page load
    // and when the active service changes
    tracking.track('help_eap_service_selected', {
      serviceId: activeService.id,
      serviceName: activeService.serviceName,
    });
  }, [activeService]);

  const handleTabClick = (serviceId: string, serviceHash: string) => {
    setActiveServiceId(serviceId);
    window.location.hash = serviceHash; // Update hash in URL
  };

  return (
    <TabMenuContainer>
      <TabList
        role="tablist"
        aria-label={translate('help_service_tabs.tab_list.a11y_label')}
      >
        {eapServices.map(service => (
          <Tab
            key={service.id}
            active={service.id === activeService.id}
            aria-selected={service.id === activeService.id}
            aria-controls={`tab-content-${activeService.id}`}
            id={`tab-${service.id}`}
            onClick={() => handleTabClick(service.id, service.icon)}
            role="tab"
            tabIndex={service.id === activeService.id ? 0 : -1}
          >
            <HelpIcon icon={service.icon} />
            <TabTitle>{service.serviceName}</TabTitle>
          </Tab>
        ))}
      </TabList>
      <TabContent
        role="tabpanel"
        tabIndex={0}
        aria-labelledby={`tab-${activeService.id}`}
        id={`tab-content-${activeService.id}`}
      >
        <ServiceDetailHeader>
          <ServiceDetailTitleContainer>
            <ServiceDetailTitle>{activeService.serviceName}</ServiceDetailTitle>
            <HelpContactLink
              href={`
        tel:${contactNumber}
      `}
              target="_blank"
              onClick={() => {
                tracking.track('help_service_contact_number_clicked', {
                  contactNumber,
                  serviceId: activeService.id,
                  serviceName: activeService.serviceName,
                  countryCode,
                });
              }}
              aria-label={translate('eap_contact_number.a11y_label', {
                contactNumber,
              })}
            >
              <HelpPhoneIcon />
              <HelpContactText>{contactNumber}</HelpContactText>
            </HelpContactLink>
          </ServiceDetailTitleContainer>
          <ServiceDetailSubtitle>
            {translate('help_service_tabs.service_tab_panel.subtitle', {
              companyName: groupName,
            })}
          </ServiceDetailSubtitle>
        </ServiceDetailHeader>
        <ServiceDetailDescription
          dangerouslySetInnerHTML={{ __html: activeService.description }}
        />
      </TabContent>
    </TabMenuContainer>
  );
};

export default HelpServicesTabs;
