import React from 'react';
import { IconProps } from '../IconProps';
const SvgBroom = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" fill="none" {...props}>
    <path
      stroke="#0D0300"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.419 7.063a13.3 13.3 0 004.54 4.457m4.485-9.374l-5.22 5.221"
    />
    <path
      stroke="#0D0300"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.223 7.367c2.807 2.463-.068 5.914-1.887 7.368a1.798 1.798 0 01-1.612.333c-2.44-.694-3.904-2.734-4.168-5.366 1.222-.167 1.948-.887 3.064-2.016C6.006 6.28 8 6.294 9.223 7.367z"
    />
  </svg>
);
export default SvgBroom;
