import React from 'react';
import { styled } from 'styles';
import { rem } from 'polished';
import RoutePath from 'App/RoutePath';
import { useTranslation } from 'react-i18next';
import { tracking } from 'App/Tracking';
import { HelpIcon } from '../../Help/HelpIcons';
import {
  BannerContainer,
  CardWrapper,
  CardActionsContainer,
  CardContentContainer,
  CardImageContainer,
  TextWrapper,
  Title,
  Subtitle,
  SupportContainer,
  StyledImage,
  SupportButton,
  SupportTitle,
  SupportButtonText,
  SupportDescription,
  BrowseButton,
  HelpPhoneIcon,
} from './HelpBanner.styled';
import { getHelpEAPServices_helpEAPServices as helpEAPservice } from './hooks/__generated__/getHelpEAPServices';

export const StyledHelpIcon = styled(HelpIcon).attrs(() => ({}))`
  margin-right: ${rem(8)};
  margin-bottom: ${rem(1)};
  align-self: center;
`;

type HelpBannerProps = {
  assetToken: string;
  featuredService: helpEAPservice;
};

const HelpBanner = ({ assetToken, featuredService }: HelpBannerProps) => {
  const { t: translate } = useTranslation('help', {
    keyPrefix: 'homepage.banner',
  });

  const { serviceName, id: serviceId, icon: serviceIcon } = featuredService;

  return (
    <BannerContainer>
      <CardWrapper>
        <CardContentContainer>
          <TextWrapper>
            <Title level={2}>{translate('title')}</Title>
            <Subtitle>{translate('description')}</Subtitle>
          </TextWrapper>
          <CardActionsContainer>
            <BrowseButton
              label={translate('browse_button')}
              to={{
                pathname: RoutePath.Talk,
              }}
              onClick={() => {
                tracking.track('help_homepage_talk_clicked');
              }}
            />
          </CardActionsContainer>
        </CardContentContainer>
        <CardImageContainer
          to={{
            pathname: RoutePath.Talk,
          }}
          tabIndex={-1}
        >
          <StyledImage
            src={`https://assets.unmind.com/image/upload/v1731316082/talk_help_homepage_banner.png?${assetToken}`}
          />
        </CardImageContainer>
      </CardWrapper>
      <SupportContainer>
        <SupportTitle level={2}>
          <HelpIcon icon={serviceIcon} height={20} width={20} />
          {serviceName}
        </SupportTitle>
        <SupportDescription>
          {translate('support.description')}
        </SupportDescription>
        <SupportButton
          label={
            <>
              <SupportButtonText
                aria-label={translate('support.a11y_label', {
                  serviceName,
                })}
              >
                {translate('support.button')}
              </SupportButtonText>
              <HelpPhoneIcon />
            </>
          }
          to={{
            pathname: `${RoutePath.Help}`,
            hash: serviceIcon,
          }}
          onClick={() => {
            tracking.track('help_homepage_service_clicked', {
              serviceId,
              serviceName,
            });
          }}
        />
      </SupportContainer>
    </BannerContainer>
  );
};

export default HelpBanner;
