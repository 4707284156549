import invariant from 'invariant';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { getMSTeamsDevice, isMSTeams } from 'utils/MSTeams';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { logException } from '../logging';
import { Events } from './Events';

export enum ParentAppName {
  MSTeamsAppName = 'ms-teams',
  UnmindAppName = 'native-unmind',
}

export enum PlatformType {
  Desktop = 'desktop',
  Web = 'web',
  IOS = 'ios',
  Android = 'android',
  MobileIframe = 'mobile-iframe',
}

export const getPlatformType = (): PlatformType | null => {
  if (!isMSTeams()) {
    return PlatformType.Web;
  }

  const currentDevice = getMSTeamsDevice();

  if (currentDevice === 'unknown') {
    return null;
  }

  if (Object.values(PlatformType).includes(currentDevice)) {
    return currentDevice;
  }

  return null;
};
export interface TrackingUser {
  activationDate: string | null;
  createdAt: string;
  uuid: string | null;
  groupId: string | undefined;
  client: string | undefined;
  clientTier: string | undefined;
  subdomain: string | undefined;
  praiseDirectoryEnabled: boolean;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  userType: string | undefined;
  accessType: string | undefined;
  department: string | undefined;
  departmentId: string | undefined;
  location: string | undefined;
  locationId: string | undefined;
  // we explicitly signify null here for a non-partner user
  partner: string | null;
  identifiedAsLineManager: boolean;
}

try {
  invariant(window.analytics, 'Analytics failed to load from Segment.');
} catch (error) {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(error);
  }
}

const identifyUser = ({
  userId,
  traits,
  callback,
}: {
  userId: string | null | undefined;
  traits: TrackingUser;
  callback?(): void;
}) => {
  const { analytics } = window;

  if (!userId) {
    logException(
      new Error('No user id available when identifying user for tracking'),
    );

    analytics.identify(traits, callback);
  } else {
    analytics.identify(userId, traits, callback);
  }
};

interface EventData {
  [key: string]: any;
}

const track = (event: Events, data?: EventData) => {
  const { analytics } = window;

  analytics.track(event, {
    ...data,
    parentApp: isMSTeams()
      ? ParentAppName.MSTeamsAppName
      : ParentAppName.UnmindAppName,
    platformType: getPlatformType(),
    subdomain: getSubdomainFromUrl(),
  });
};

export interface Tracking {
  track: typeof track;
  identifyUser: typeof identifyUser;
}

export default (isNil(window.analytics)
  ? {
      track: noop,
      identifyUser: noop,
    }
  : {
      track,
      identifyUser,
    }) as Tracking;
