import { gql, useQuery } from '@apollo/client';
import { getHelpEAPServices } from './__generated__/getHelpEAPServices';

export const GET_EAP_SERVICES = gql`
  query getHelpEAPServices {
    helpEAPServices {
      id
      serviceName
      icon
    }
  }
`;

export const IN_PERSON_SUPPORT_ID = '317yVoxNYJO2pCXrRkS1Eu';

export const useGetEAPServices = () => {
  const { data, loading, error, refetch } = useQuery<getHelpEAPServices>(
    GET_EAP_SERVICES,
    {
      errorPolicy: 'all',
      returnPartialData: false,
    },
  );

  const helpHomeEAPServices = (data?.helpEAPServices ?? []).filter(
    service => service.id !== IN_PERSON_SUPPORT_ID,
  );

  return { data: helpHomeEAPServices, loading, error, refetch };
};
