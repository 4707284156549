import { useState } from 'react';
import { logException } from 'App/logging';
import { clearConversationHistoryConversationClearPost } from 'Assistant/__generated__/client';
import { isEmpty } from 'lodash';
import { useAuthHeader } from './useAuthHeader';

type ClearConversationParams = {
  conversationId?: string;
  onSuccessCallback(): void;
  onAuthErrorCallback(): void;
};

export type ClearConversationOutput = {
  isClearingConversation: boolean;
  hadErrorClearingConversation: boolean;
  clearConversation(): Promise<void>;
};

function useClearConversation({
  conversationId,
  onSuccessCallback,
  onAuthErrorCallback,
}: ClearConversationParams): ClearConversationOutput {
  const [isClearingConversation, setIsClearingConversation] = useState(false);
  const [hadErrorClearingConversation, setHadErrorClearingConversation] =
    useState(false);
  const accessTokenHeader = useAuthHeader();

  const clearConversation = async () => {
    try {
      if (isEmpty(accessTokenHeader)) {
        throw new Error(
          `Error clearing conversation ${conversationId}: could not fetch access token header`,
        );
      }

      setIsClearingConversation(true);
      setHadErrorClearingConversation(false);

      const response = await clearConversationHistoryConversationClearPost({
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'client-platform': 'web',
          ...accessTokenHeader,
        },
      });

      if (response.status !== 200) {
        if (response.status === 401) {
          onAuthErrorCallback();
        }

        throw new Error(`Error clearing conversation ${conversationId}`);
      }

      setIsClearingConversation(false);

      return onSuccessCallback();
    } catch (ex) {
      logException(ex);
      setIsClearingConversation(false);
      setHadErrorClearingConversation(true);
    }
  };

  return {
    isClearingConversation,
    hadErrorClearingConversation,
    clearConversation,
  };
}

export { useClearConversation };
