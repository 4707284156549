import RoutePath from 'App/RoutePath';
import {
  AuthWrapper,
  NewAuthWrapperBlock,
  AuthWrapperTitle,
} from 'LoggedOut/AuthWrapper';
import { EmailOnlyFormFields } from 'Shared/Form/Formik';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { rem } from 'polished';
import styled from 'styled-components';
import { setStoredValue } from 'utils/storageHelpers';
import { StorageKey } from 'utils/useStorage';
import { AuthTypeEnum } from '__generated__/globalTypes';
import { useRedirectToSubdomain } from 'utils/useRedirectToSubdomain';
import EmailInputForm from 'LoggedOut/SignUp/Forms/EmailInputForm';
import { Loader } from '@unmind/design-system-components-web';
import { REMOVE_TEAMS_LOGIN_CONTEXT_PARAM } from 'utils/MSTeams/MSTeamsHelpers';
import { tracking } from '../../App/Tracking';
import { getOrganisationsByEmailDomain } from './__generated__/getOrganisationsByEmailDomain';
import { Organisation, SelectOrganisation } from './SelectOrganisation';
import { useGetOrganisationsByTenantIds } from './useGetOrganisationsByTenantIds';

export const FormContent = styled.div`
  padding-top: ${rem(16)};
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  width: 100%;
`;

export const FIND_ORGANISATON_BY_EMAIL_DOMAIN_QUERY = gql`
  query getOrganisationsByEmailDomain($email: String!) {
    organisationDomainsByEmailDomain(email: $email) {
      primarySubDomain
      id
      name
      active
      authType
    }
  }
`;

type FormFields = EmailOnlyFormFields;

export const StartV2 = () => {
  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'start_v2',
  });
  const [showSelectOrg, setShowMultiOrgSelect] = useState(false);
  const history = useHistory();
  const formikRef = useRef<Formik<FormFields>>(null);
  const [error, setError] = useState('');
  const { redirectToSubdomain } = useRedirectToSubdomain();
  const searchParams = new URLSearchParams(window.location.search);
  const skipQueryTenantId = searchParams.get(REMOVE_TEAMS_LOGIN_CONTEXT_PARAM);

  const [organisations, setOrganisations] = useState<Organisation[] | null>(
    null,
  );
  const { loading: getOrganisationsByTenantIdsLoading } =
    useGetOrganisationsByTenantIds({
      onComplete: setOrganisations,
      skip: skipQueryTenantId === 'true',
    });

  const getRoutePathFromAuthType = (authType?: AuthTypeEnum): RoutePath => {
    if (!authType) {
      return RoutePath.Login;
    }
    if (authType === AuthTypeEnum.AUTH0_SSO) {
      return RoutePath.LoginWithSSO;
    }

    return RoutePath.LoginWithCredentials;
  };

  const [findOrganisationByEmailDomain, { loading }] =
    useLazyQuery<getOrganisationsByEmailDomain>(
      FIND_ORGANISATON_BY_EMAIL_DOMAIN_QUERY,
      {
        onCompleted: organisationsData => {
          const activeOrganisations: Organisation[] =
            organisationsData.organisationDomainsByEmailDomain?.filter(
              org => org !== null && org.active,
            ) as Organisation[];

          if (!activeOrganisations || activeOrganisations.length === 0) {
            history.push(RoutePath.LoginWithCredentials);
          }
          setOrganisations(activeOrganisations);
        },
      },
    );

  useEffect(() => {
    if (organisations && organisations.length === 1) {
      redirectToSubdomain({
        subdomain: organisations[0].primarySubDomain,
        routePath: getRoutePathFromAuthType(organisations[0]?.authType),
      });
    }
    if (organisations && organisations.length > 1) {
      setShowMultiOrgSelect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisations]);

  const onSubmit = async (values: FormFields) => {
    setStoredValue({
      key: StorageKey.EMAIL,
      value: values.emailInput,
    });

    tracking.track('start_enter_email_button_clicked');

    return findOrganisationByEmailDomain({
      variables: { email: values.emailInput },
    });
  };

  const emailLabel = translate('email_field.label');
  const submitLabel = translate('submit_button.label');

  return (
    <AuthWrapper
      newDesign
      showBackButton={showSelectOrg}
      backButtonOnClick={() => {
        setShowMultiOrgSelect(false);
        setOrganisations(null);
      }}
    >
      <NewAuthWrapperBlock>
        {showSelectOrg && organisations ? (
          <SelectOrganisation
            organisations={organisations}
            redirectToSubdomain={(subdomain, authType: AuthTypeEnum) =>
              redirectToSubdomain({
                subdomain,
                routePath: getRoutePathFromAuthType(authType),
              })
            }
          />
        ) : getOrganisationsByTenantIdsLoading ? (
          <Loader />
        ) : (
          <>
            <AuthWrapperTitle>{translate('title')}</AuthWrapperTitle>
            <FormContent>
              <EmailInputForm
                formikRef={formikRef}
                inputLabel={emailLabel}
                submitLabel={submitLabel}
                onSubmit={onSubmit}
                loading={loading}
                setError={setError}
                error={error}
              />
            </FormContent>
          </>
        )}
      </NewAuthWrapperBlock>
    </AuthWrapper>
  );
};
