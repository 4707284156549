import React, { useCallback } from 'react';
import { Accordion, BodyText } from '@unmind/design-system-components-web';
import { useTranslation } from 'react-i18next';
import { rem } from 'polished';
import styled from 'styled-components';
import SegmentHeader from 'Series/SingleSeries/SeriesDay/SeriesSegment/SegmentHeader';
import { Check, CheckEmpty, Lock } from 'icons';
import { useHistory } from 'react-router';
import RoutePath from '../../../../App/RoutePath';
import {
  useCompletedSegments,
  useGetSessionSegments,
} from '../useSingleSeriesWithSegments';
import { GetSingleSeries_singleSeries_seriesDays as SeriesSessions } from '../__generated__/GetSingleSeries';
import { SessionSegments_seriesDay_segments as SessionSegment } from '../__generated__/SessionSegments';

interface CourseContentProps {
  sessions: SeriesSessions[];
  minsPerDay: number;
  activeSessionTitle: string;
  slug: string;
}

const CheckIcon = styled(Check).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(21)};
  height: ${rem(21)};
  flex-shrink: 0;
  margin-right: ${rem(9)};
`;

const CheckEmptyIcon = styled(CheckEmpty).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(21)};
  flex-shrink: 0;
  height: ${rem(21)};
  margin-right: ${rem(9)};
`;

const CourseContentTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.bold,
  color: theme.colors.text.primary,
}))`
  margin-top: ${rem(24)};
`;

const SessionInformation = styled.div`
  display: flex;
  margin-top: ${rem(8)};
  margin-bottom: ${rem(24)};
`;

const SegmentContent = styled.div`
  display: flex;
  align-items: center;
  list-style-type: none;
  justify-content: flex-start;
  margin-left: ${rem(8)};
`;

const SegmentTitle = styled.li<{ clickable: boolean }>`
  flex-grow: 1;
  margin-left: ${rem(16)};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

const AccordionContent = styled.div`
  padding: ${rem(16)};
`;

const LockedIcon = styled(Lock).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(21)};
  height: ${rem(21)};
  flex-shrink: 0;
  margin-right: ${rem(9)};
`;

interface AccordionItemContentProps {
  segments: SessionSegment[];
  nextUnlockedSegmentId: string;
  completedSegmentIds: string[];
  onClick(index: number): void;
}

const AccordionItemContent = ({
  segments,
  nextUnlockedSegmentId,
  completedSegmentIds,
  onClick,
}: AccordionItemContentProps) => (
  <AccordionContent>
    {segments.map((segment, index) => {
      const isNextSegment = nextUnlockedSegmentId === segment?.id;
      const isSegmentUnlocked =
        (segment?.id && completedSegmentIds.includes(segment?.id)) ||
        isNextSegment;

      return (
        <SegmentContent key={segment?.id}>
          {segment?.icon?.stringValue && (
            <SegmentHeader
              title={''}
              idx={0}
              isComplete={false}
              isExpanded={false}
              segmentsLeft={0}
              icon={segment?.icon.stringValue}
            />
          )}
          <SegmentTitle
            clickable={isSegmentUnlocked}
            onClick={() => {
              if (isSegmentUnlocked) {
                onClick(index + 1);
              }
            }}
          >
            {segment?.title}
          </SegmentTitle>
          {segment?.id && completedSegmentIds.includes(segment?.id) ? (
            <CheckIcon />
          ) : isNextSegment ? (
            <CheckEmptyIcon />
          ) : (
            <LockedIcon />
          )}
        </SegmentContent>
      );
    })}
  </AccordionContent>
);

export const CourseContent = ({
  sessions,
  minsPerDay,
  activeSessionTitle,
  slug,
}: CourseContentProps) => {
  const { t: translate } = useTranslation('courses');
  const history = useHistory();

  const sessionIds = sessions
    .map(session => session.id)
    .filter(id => id !== null) as string[];

  const { sessionsWithSegments } = useGetSessionSegments(sessionIds);

  const { completedSegmentIds } = useCompletedSegments(sessions);

  const courseStarted = completedSegmentIds.length > 0;

  const getNextUnlockedSegmentId = () => {
    const activeSession = sessions.find(
      day => day.title === activeSessionTitle,
    );
    if (!activeSession) return '';

    return (
      activeSession.segments?.find(segment => {
        if (!segment?.id || completedSegmentIds.includes(segment.id))
          return false;

        return segment.id;
      })?.id || ''
    );
  };

  const handleSegmentClick = useCallback(
    (index: number) => {
      history.push(`${RoutePath.Series}/${slug}/day/${index + 1}`);
    },
    [history, slug],
  );

  const items = sessions.map((session, index) => ({
    header: session.title,
    value: session.title || '',
    content: (
      <AccordionItemContent
        segments={
          sessionsWithSegments
            .find(({ sessionId }) => sessionId === session.id)
            ?.seriesDay?.segments?.filter(
              segment => segment !== null,
            ) as SessionSegment[]
        }
        nextUnlockedSegmentId={getNextUnlockedSegmentId()}
        completedSegmentIds={completedSegmentIds}
        onClick={() => handleSegmentClick(index)}
      />
    ),
  }));

  return (
    <>
      <CourseContentTitle>
        {translate('course_homepage.course_content.title')}
      </CourseContentTitle>
      <SessionInformation>
        {translate('course_card.course_information', {
          numberOfSessions: sessions.length,
          avgLengthSession: minsPerDay,
        })}
      </SessionInformation>
      <Accordion
        items={items}
        defaultValue={courseStarted ? [activeSessionTitle] : []}
      />
    </>
  );
};
