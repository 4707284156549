import { rem, rgba } from 'polished';
import React, {
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { animated, useTransition } from 'react-spring';
import { css, styled, ThemeInterface } from 'styles';
import { Close } from 'icons';
import { small, medium } from '../../utils';
import { WrappingButton } from '../Accessibility';
import Portal from '../Portal';
import HeadingText from '../Typography/HeadingText';
import BodyText from '../Typography/BodyText';

const CloseIcon = styled(Close).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.primary,
    secondaryColor: theme.colors.text.primary,
  }),
)`
  height: ${rem('12px')};
  width: ${rem('12px')};
`;

const CloseButton = styled(WrappingButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${rem('16px')} ${rem('16px')} ${rem('0px')} ${rem('10px')};

  ${small(css`
    margin: ${rem('24px')} ${rem('24px')} ${rem('0px')} ${rem('10px')};
  `)}
`;

type ModalSize = 'small' | 'large';

type ModalVariant = 'overnavigation' | 'overlayed' | 'standard';

const ModalCard = styled(animated.div)<{ size: ModalSize }>`
  background: ${({ theme }) => theme.colors.background.primary};
  border: solid ${rem(1)} ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${rem(12)};
  overflow: hidden;
  box-shadow: 0px 0px ${rem(5)} rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-self: center;
  position: relative;
  min-height: ${rem(200)};
  max-width: 90%;
  max-height: 90%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }

  ${small(
    // @ts-ignore
    css<{ size: ModalSize }>`
      width: ${({ size }) => (size === 'small' ? rem(400) : rem(528))};
      min-height: ${rem(300)};
    `,
  )}

  ${({ size }) =>
    size === 'large'
      ? medium(css`
          width: ${rem(850)};
        `)
      : undefined};
`;

const ModalContainer = styled(animated.div)<{ variant: ModalVariant }>`
  align-content: center;
  align-items: center;
  background: ${({ theme }) => rgba(theme.colors.background.inverse, 0.7)};
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  padding: ${rem('16px')};
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;

  ${({ variant }) =>
    variant === 'standard' &&
    css`
      z-index: ${({ theme }) => theme.zIndex.modal};
    `}

  ${({ variant }) =>
    variant === 'overlayed' &&
    css`
      z-index: ${({ theme }) => theme.zIndex.overModal};
    `}

  ${({ variant }) =>
    variant === 'overnavigation' &&
    css`
      z-index: ${({ theme }) => theme.zIndex.overMobileNav};
    `}
`;

export const ModalTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
  ],
  weight: theme.typography.fontWeights.medium,
  id: 'modal-title',
}))``;

export const ModalCopy = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  id: 'modal-copy',
}))`
  margin-top: ${rem(16)};
`;

export const ModalContent = styled.div`
  /* height: 100%; */
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;
  padding: ${rem('4px')} ${rem('16px')} ${rem('16px')} ${rem('16px')};

  ${small(css`
    padding: ${rem('8px')} ${rem('24px')} ${rem('24px')} ${rem('24px')};
  `)}
`;

export type ModalProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Boolean flag which can be set to open modal programtically,
   * without button press
   */
  open?: boolean;

  /**
   * Boolean flag which determines if the modal header should contain a
   * closing icon
   */
  closeHeader?: boolean;

  /**
   * Optional close callback function to control external state which might be
   * controlling the modal show state, like the open prop.
   */
  onCloseCallback?(trackingData?: object): void;

  /**
   * Optional HTML Element ref, which will be used to target focus after
   * modal is closed, use for accessibility purposes.
   */
  returnFocusTo?: HTMLButtonElement | HTMLElement | null;

  /**
   * Render prop funtion which should contain all child elements wanting
   * to be displayed in main body of the modal. The open and close state
   * methods are passed to this as props.
   */
  modalContent(modalRenderProps: ModalRenderProps): ReactNode;

  /**
   * Optional render prop method to define a button to open the modal
   * the open and close state methods are passed to this as props.
   */
  openModalButton?(modalRenderProps: ModalRenderProps): ReactNode;

  /**
   * Optional size prop to change the static width of the modal
   */
  size?: ModalSize;

  /**
   * Optional variant prop to change the z-index of the modal to correspond
   * with theme based z-index values
   */
  variant?: ModalVariant;
};

interface ModalRenderProps {
  open(): void;
  close(trackingData?: object): void;
  returnFocusToRef?: React.RefObject<HTMLButtonElement>;
}

export const useModalState = (
  onCloseCallback?: (trackingData?: object) => void,
  initialShowState: boolean = false,
) => {
  const [show, setShow] = useState(initialShowState);
  const hasTabbed = useRef<boolean>(false);

  return {
    show,
    setShow,
    openModal: () => {
      hasTabbed.current = false;
      setShow(true);
    },
    closeModal: (trackingData?: { [key: string]: string }) => {
      if (onCloseCallback) {
        onCloseCallback({ ...trackingData });
      }
      setShow(false);
    },
    hasTabbed,
  };
};

const Modal = ({
  closeHeader,
  openModalButton,
  modalContent,
  open,
  onCloseCallback,
  size = 'small',
  variant = 'standard',
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const openModalButtonRef = useRef<HTMLButtonElement>(null);

  const { show, openModal, closeModal, hasTabbed } = useModalState(
    onCloseCallback,
    open,
  );

  if (open && !show) {
    openModal();
  }

  const handleTabKey = (e: KeyboardEvent): void => {
    if (modalRef.current !== null) {
      const modalElements = modalRef.current.querySelectorAll<HTMLElement>(`
        button:not(:disabled),
        [href],
        input:not([tabindex='-1']),
        select,
        textarea,
        [tabindex]:not([tabindex='-1'])
      `);

      if (modalElements.length > 0) {
        const firstElement = modalElements[0];
        const lastElement = modalElements[modalElements.length - 1];

        if (!hasTabbed.current) {
          firstElement.focus();
          hasTabbed.current = true;

          e.preventDefault();

          return;
        }

        if (!e.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();

          e.preventDefault();

          return;
        }

        if (e.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();

          e.preventDefault();

          return;
        }
      }
    }
  };

  const handleEscKey = (e: KeyboardEvent): void => {
    if (openModalButtonRef.current) {
      openModalButtonRef.current.focus();
    }
    closeModal({
      actionMethod: 'close',
    });
    e.preventDefault();
  };

  const handleEscClick = (): void => {
    if (openModalButtonRef.current) {
      openModalButtonRef.current.focus();
    }
    closeModal({
      actionMethod: 'close',
    });
  };

  const handleBackgroundClick = (): void => {
    if (openModalButtonRef.current) {
      openModalButtonRef.current.focus();
    }
    closeModal({
      actionMethod: 'clickOutside',
    });
  };

  const keyListenersMap = new Map([
    [27, handleEscKey],
    [9, handleTabKey],
  ]);

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      const listener = keyListenersMap.get(e.keyCode);

      if (listener) {
        listener(e);
      }
    };

    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  });

  const modalTransitions = useTransition(show, null, {
    enter: { opacity: 1, transform: 'scale3d(1, 1, 1)' },
    from: { opacity: 0, transform: 'scale3d(0.7, 0.7, 1)' },
    leave: { opacity: 0, transform: 'scale3d(0.7, 0.7, 1)' },
    config: { duration: 150 },
  });

  return (
    <>
      {openModalButton &&
        openModalButton({
          open: openModal,
          close: closeModal,
          returnFocusToRef: openModalButtonRef,
        })}
      <Portal>
        {modalTransitions.map(
          ({ item: showModal, key, props: { opacity, ...styles } }) =>
            showModal && (
              <ModalContainer
                aria-modal="true"
                aria-labelledby="modal-title"
                aria-describedby="modal-copy"
                key={key}
                onClick={handleBackgroundClick}
                style={{ opacity }}
                role="dialog"
                variant={variant}
              >
                <ModalCard
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  ref={modalRef}
                  style={styles}
                  size={size}
                >
                  {closeHeader && (
                    <CloseButton
                      onClick={handleEscClick}
                      role="button"
                      aria-label="close modal"
                    >
                      <CloseIcon />
                    </CloseButton>
                  )}
                  {modalContent({ open: openModal, close: closeModal })}
                </ModalCard>
              </ModalContainer>
            ),
        )}
      </Portal>
    </>
  );
};

export default Modal;
