import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace, ParseKeys } from 'i18next';
import { tracking } from 'App/Tracking';
import { useLocationData } from 'Talk/hooks/useLocationData';
import { getIsoCodeTwo } from 'Talk/lib/constants/getIsoCodeTwo';
import { StylelessButton } from 'Shared/Button';
import { BodyCopy, HeaderSlice, PageTitle } from '../../pages/common/styles';
import Modal from '../../../Shared/Modal';
import { SetLocationForm } from '../SetLocation';
import {
  LocationFormTitle,
  LocationFormCopy,
  UpdateLocationFormWrapper,
} from '../SetLocation/styles';
import { LimitsHeader } from '../LimitsHeader';
import {
  HeaderWrapper,
  IconTextWrapper,
  LocationHeaderSlice,
  LocationPin,
} from './styles';

const CountryLabel = ({ countryName }: { countryName?: string | null }) => {
  const { t: translateCountry } =
    useTranslation<Namespace<'country'>>('country');
  if (!countryName) return null;

  const isoCodeTwo = getIsoCodeTwo(countryName);

  return <>{translateCountry(isoCodeTwo as ParseKeys<Namespace<'country'>>)}</>;
};

export const Header = () => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');

  const { userLocationData, hasValidLocationData, requiresState } =
    useLocationData();

  return (
    <HeaderWrapper>
      <HeaderSlice>
        <PageTitle>{t('title')}</PageTitle>
        <LimitsHeader />
      </HeaderSlice>
      <LocationHeaderSlice>
        {hasValidLocationData ? (
          <Modal
            openModalButton={({ open }) => (
              <StylelessButton onClick={open} className="">
                <IconTextWrapper>
                  <LocationPin />
                  {requiresState ? (
                    <BodyCopy>{userLocationData?.user?.state?.value}</BodyCopy>
                  ) : (
                    <BodyCopy>
                      <CountryLabel
                        countryName={userLocationData?.user?.country?.value}
                      />
                    </BodyCopy>
                  )}
                </IconTextWrapper>
              </StylelessButton>
            )}
            modalContent={({ close }) => (
              <UpdateLocationFormWrapper>
                <LocationFormTitle>
                  {t('location_modal.update_location_title')}
                </LocationFormTitle>
                <LocationFormCopy>{t('location_modal.body')}</LocationFormCopy>
                <SetLocationForm
                  onSubmitSuccess={(country, state) => {
                    tracking.track('place-of-residence-updated', {
                      country: country.value,
                      state: state.value,
                      source: 'talk',
                    });
                    close();
                  }}
                />
              </UpdateLocationFormWrapper>
            )}
          />
        ) : null}
      </LocationHeaderSlice>
    </HeaderWrapper>
  );
};
