import {
  ChatTextMessageInput,
  MappedConversationMessage,
} from 'Assistant/__generated__/client.schemas';
import { HistoryErrorType, Message } from '../types';
import { ChatbotMessageRole } from '../enums/ChatbotMessageRole';

export const mapHistoryToMessage = ({
  content,
  created_at,
  id,
  role,
}: MappedConversationMessage): Message => ({
  id: id,
  createdAt: created_at,
  ...(role === ChatbotMessageRole.user
    ? {
        role: ChatbotMessageRole.user,
        content: content.body,
        topic: null,
        starterId: null,
      }
    : { role: ChatbotMessageRole.assistant, source: content.body }),
});

export const mapMessageToInput = (message: Message): ChatTextMessageInput => {
  if (message.role === ChatbotMessageRole.user) {
    return {
      value: message.content,
      type: 'text',
    };
  }

  return {
    value: message.source,
    type: 'text',
  };
};

export const mapHistoryErrorType = (
  fetchHistoryError: boolean,
  featureUnavailable: boolean,
  userSessionExpired: boolean,
): HistoryErrorType => {
  if (userSessionExpired) {
    return 'USER_SESSION_EXPIRED';
  }

  if (featureUnavailable) {
    return 'FEATURE_UNAVAILABLE';
  }

  if (fetchHistoryError) {
    return 'RETRY';
  }

  return null;
};
