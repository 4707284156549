import React from 'react';
import { IconProps } from '../IconProps';
const SvgCareForOthers = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#CareForOthers_svg__clip0_1781_32155)" fill="#0D0300">
      <path d="M7.333 6.667a3 3 0 100-6 3 3 0 000 6zM6.667 10.881c0-1.16.558-2.182 1.407-2.83-.243-.03-.49-.051-.74-.051-3.308 0-6 2.692-6 6v.52l.504.127c1.824.456 3.66.683 5.495.683.608 0 1.214-.034 1.821-.084-1.25-1.132-2.487-2.707-2.487-4.365z" />
      <path d="M12 15.666c.887-.412 4-2.694 4-4.785 0-1.223-.965-2.215-2.154-2.215-.783 0-1.37.506-1.846 1.074-.476-.568-1.063-1.074-1.846-1.074-1.19 0-2.154.992-2.154 2.215 0 2.092 3.113 4.374 4 4.785z" />
    </g>
    <defs>
      <clipPath id="CareForOthers_svg__clip0_1781_32155">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCareForOthers;
