import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { GetSeriesSegmentsCompleted } from './__generated__/GetSeriesSegmentsCompleted';
import {
  GetSingleSeries,
  GetSingleSeries_singleSeries_seriesDays as singleSeriesDays,
} from './__generated__/GetSingleSeries';
import { SessionSegments } from './__generated__/SessionSegments';

export const COURSE_QUERY = gql`
  query GetSingleSeries($slug: String!) {
    singleSeries(slug: $slug) {
      id
      title
      subtitle
      totalDays
      daysCompleted
      benefit1 {
        stringValue
      }
      benefit2 {
        stringValue
      }
      benefit3 {
        stringValue
      }
      description {
        longValue
      }
      minsPerDay {
        numericValue
      }
      seriesDays {
        title
        orderNo
        id # Unused but needed to avoid caching bug (see ENG-1285)
        segments {
          id
        }
      }
      hasCompletedPreviousVersion
      isNotAnonymised
    }
  }
`;

export const SESSION_SEGMENTS = gql`
  query SessionSegments($sessionId: String!) {
    seriesDay(id: $sessionId) {
      segments {
        id
        title
        icon {
          stringValue
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

const SEGMENTS_COMPLETED_QUERY = gql`
  query GetSeriesSegmentsCompleted($sessionId: String) {
    seriesSegmentsCompleted(seriesDayId: $sessionId) {
      edges {
        node {
          segmentId
        }
      }
    }
  }
`;

export const useSingleSeriesWithSegments = (slug: string) => {
  const {
    data: seriesData,
    loading,
    error,
  } = useQuery<GetSingleSeries>(COURSE_QUERY, {
    variables: { slug },
    fetchPolicy: 'cache-first',
  });

  return {
    seriesData: seriesData?.singleSeries,
    loading,
    error,
  };
};

export const useCompletedSegments = (seriesDays: singleSeriesDays[]) => {
  const [completedSegmentIds, setCompletedSegmentIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const [getSeriesSegmentsCompleted] = useLazyQuery<GetSeriesSegmentsCompleted>(
    SEGMENTS_COMPLETED_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    const fetchCompletedSegments = async () => {
      setLoading(true);

      const segmentIds: string[] = [];

      await Promise.all(
        seriesDays.map(async day => {
          const { data, error: seriesSegmentsCompletedError } =
            await getSeriesSegmentsCompleted({
              variables: { sessionId: day.id },
            });

          if (seriesSegmentsCompletedError) {
            setError(seriesSegmentsCompletedError);
          }

          if (data) {
            data.seriesSegmentsCompleted?.edges?.forEach(edge => {
              if (edge?.node?.segmentId) {
                segmentIds.push(edge.node.segmentId);
              }
            });
          }
        }),
      );

      setCompletedSegmentIds(segmentIds);

      setLoading(false);
    };

    void fetchCompletedSegments();
  }, [seriesDays, getSeriesSegmentsCompleted]);

  return {
    completedSegmentIds,
    loading,
    error,
  };
};

interface SessionWithSegments extends SessionSegments {
  sessionId: string;
}

export const useGetSessionSegments = (sessionIds: string[]) => {
  const [sessionsWithSegments, setSessions] = useState<SessionWithSegments[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [getSeriesSegments] = useLazyQuery<SessionSegments>(SESSION_SEGMENTS);

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);

      const fetchedSessions: SessionWithSegments[] = [];

      await Promise.all(
        sessionIds.map(async sessionId => {
          const { data, error: seriesSegmentsError } = await getSeriesSegments({
            variables: { sessionId },
          });

          if (seriesSegmentsError) {
            setError(seriesSegmentsError);
          }

          if (data) {
            fetchedSessions.push({
              ...data,
              sessionId,
            });
          }
        }),
      );

      setSessions(fetchedSessions);
      setLoading(false);
    };

    fetchSessions();
  }, []);

  return {
    sessionsWithSegments,
    loading,
    error,
  };
};
