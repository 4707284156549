import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CourseDescription as CourseDescriptionText,
  CourseDescriptionBenefits,
  CourseDescriptionBenefitsList,
} from './styles';

interface CourseDescriptionProps {
  description: string;
  benefits: string[];
}

export const CourseDescription = ({
  description,
  benefits,
}: CourseDescriptionProps) => {
  const { t: translate } = useTranslation('courses');

  return (
    <>
      <CourseDescriptionText>{description}</CourseDescriptionText>
      <CourseDescriptionText>
        {translate('overview.benefits_title')}
      </CourseDescriptionText>
      <CourseDescriptionBenefitsList>
        {benefits.map(benefit => (
          <CourseDescriptionBenefits key={benefit}>
            {benefit}
          </CourseDescriptionBenefits>
        ))}
      </CourseDescriptionBenefitsList>
    </>
  );
};
