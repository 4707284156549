import { AddToCalendar } from 'Shared/AddToCalendar';
import React from 'react';
import BackButton from 'Shared/Button/BackButton';
import { Trans, useTranslation } from 'react-i18next';
import { ExternalLink } from 'Shared/Typography';
import AlertDisclaimer from 'Shared/AlertDisclaimer';
import { Namespace } from 'i18next';
import {
  HeaderContainer,
  TitleAndSubtitle,
  Title,
  Description,
  AddToCalendarContainer,
} from './styles';

interface CourseHeaderProps {
  title: string;
  subtitle: string;
  isNotAnonymised: boolean;
}

export const CourseHeader = ({
  title,
  subtitle,
  isNotAnonymised,
}: CourseHeaderProps) => {
  const { t: translate } = useTranslation<Namespace<'courses'>>('courses');

  return (
    <>
      <HeaderContainer>
        <TitleAndSubtitle>
          <Title>
            <BackButton />
            {title}
          </Title>
          <Description>{subtitle}</Description>
        </TitleAndSubtitle>
        <AddToCalendarContainer>
          <AddToCalendar
            title={title}
            useSmallButton={true}
            description={subtitle}
            buttonTextKey={'add_to_calendar.course_reminder_text'}
            contentPath={window.location.pathname}
          />
        </AddToCalendarContainer>
      </HeaderContainer>
      {isNotAnonymised ? (
        <AlertDisclaimer
          disclaimerText={Trans({
            t: translate,
            i18nKey: 'overview.tracking_alert_disclaimer.text',
            defaults:
              "We wanted to let you know that your company can now track the completion of <bold_text>{{series_title}}</bold_text>, as part of their commitment to supporting your professional development.<br /><br />However, we understand the importance of privacy and it's something we take seriously at Unmind. We want to reassure you that the rest of your platform usage remains completely anonymised and confidential. If you have any concerns, please reach out to <email_link>support@unmind.com</email_link>.",
            components: {
              bold_text: <strong />,
              email_link: (
                <ExternalLink
                  data-testid="support-email"
                  href="mailto:support@unmind.com"
                  style={{ textDecoration: 'underline' }}
                />
              ),
            },
            values: {
              series_title: title,
            },
          })}
        />
      ) : null}
    </>
  );
};
