import { RouteComponentProps } from 'react-router';
import RoutePath from '../../../../App/RoutePath';
import { getSentAtTimestamp } from '../../../../App/Tracking/serverside';
import { tracking } from '../../../../App/Tracking';
import { WithLogSeriesActivityResponsesChildProps } from '../withLogSeriesActivity';
import { withSeriesTrackingIdsChildProps } from '../withSeriesTrackingIds';

export type onNextStep = () => void;

export interface HandleNextParams
  extends withSeriesTrackingIdsChildProps,
    WithLogSeriesActivityResponsesChildProps,
    Pick<RouteComponentProps, 'history'> {
  segmentId: string;
  slug: string;
  dayNumber: number;
  segmentNumber: number;
  segmentTitle: string;
  seconds?: number;
  handleTakeOvers?: onNextStep;
  isLastSegmentInSeries?: boolean;
  isLastSegmentInDay: boolean;
  isComplete: boolean;
}

export const handleNext = async (params: HandleNextParams) => {
  const {
    segmentId,
    id,
    seriesId,
    segmentNumber,
    slug,
    history,
    logSeriesActivity,
    dayNumber,
    seconds = 0,
    handleTakeOvers,
    isLastSegmentInSeries,
    isLastSegmentInDay,
    isComplete,
    segmentTitle,
    exploreCategoryId,
  } = params;

  if (seriesId !== undefined && id !== undefined) {
    const res = await logSeriesActivity({
      segmentId,
      dayId: id,
      seriesId,
      seconds,
      slug,
      clientSentAtUtcTimestamp: getSentAtTimestamp(),
      exploreCategoryId,
    });

    if (res.success) {
      if (isLastSegmentInSeries) {
        tracking.track('series-completed', {
          seriesId,
          seriesSlug: slug,
        });
      }

      tracking.track('series-segment-completed', {
        segmentId,
        segmentTitle,
        segmentNumber,
        dayId: id,
        dayNumber,
        seriesId,
        seriesSlug: slug,
        isInitial: !isComplete,
        exploreCategoryId: exploreCategoryId,
      });

      if (isLastSegmentInDay) {
        tracking.track('series-day-completed', {
          seriesId,
          dayId: id,
          dayNumber,
          seriesSlug: slug,
        });
      }
      const nextSegmentNumber =
        segmentNumber !== undefined ? segmentNumber + 1 : 1;

      const completedSeriesUrl = `${RoutePath.Series}/${slug}/completed`;
      const reviewDayUrl = `${RoutePath.Series}/${slug}/day/${dayNumber}/${nextSegmentNumber}`;

      /**
       * Using replace here instaed of push means that user pressing browser back
       * button will not be cycled through the individual day content and instead return to
       * overview screen or screen that they came from before starting day segments
       */
      if (isLastSegmentInSeries) {
        history.replace(completedSeriesUrl, {
          reviewDay: reviewDayUrl,
        });
      } else {
        history.replace(reviewDayUrl);
      }

      /**
       * handleTakeOvers is only provided if we're in a segment that uses take-overs
       */
      if (handleTakeOvers !== undefined) {
        handleTakeOvers();
      }
    } else {
      console.error('Error saving progress', res);
    }
  } else {
    console.error('seriesId or dayId is undefined');
  }
};
