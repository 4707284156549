import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleSelect } from '@unmind/design-system-components-web/dist/components/Form';
import { AuthTypeEnum } from '__generated__/globalTypes';
import { rem } from 'polished';
import styled from 'styled-components';
import { AuthWrapperTitle } from '../AuthWrapper';
import { SecondaryButton } from '../../Shared/SecondaryButton';
import { tracking } from '../../App/Tracking';

export type Organisation = {
  primarySubDomain: string;
  id: string;
  name: string;
  authType?: AuthTypeEnum;
};

interface SelectOrganisationProps {
  organisations: Organisation[];
  redirectToSubdomain(subdomain: string, authType?: AuthTypeEnum): void;
}

const Container = styled.div`
  margin-top: ${rem(48)};

  & > button {
    width: 100%;
    margin-bottom: ${rem(32)};
  }

  & > button:last-child {
    margin-bottom: 0;
  }
`;

export const SelectOrganisation = ({
  organisations,
  redirectToSubdomain,
}: SelectOrganisationProps) => {
  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'select_organisation',
  });

  useEffect(() => {
    tracking.track('start_select_organisation_viewed');
  }, []);

  const onSelect = (selectedOrganisation: Organisation) => {
    tracking.track('start_organisation_selected', {
      subdomain: selectedOrganisation.primarySubDomain,
    });

    redirectToSubdomain(
      selectedOrganisation.primarySubDomain,
      selectedOrganisation.authType,
    );
  };

  const onChange = (value: string | null) => {
    if (!value) return;

    const selectedOrganisation = organisations.find(
      org => org.primarySubDomain === value,
    );

    if (!selectedOrganisation) return;

    onSelect(selectedOrganisation);
  };

  const useDropdown = organisations.length > 3;

  return (
    <>
      <AuthWrapperTitle>{translate('title')}</AuthWrapperTitle>
      <Container>
        {useDropdown ? (
          <SingleSelect
            options={organisations.map(organisation => ({
              value: organisation.primarySubDomain,
              label: organisation.name,
            }))}
            onChange={onChange}
            placeholder={translate('select_placeholder')}
          />
        ) : (
          <>
            {organisations.map(organisation => (
              <SecondaryButton
                key={organisation.id}
                onClick={() => onSelect(organisation)}
                label={organisation.name}
              />
            ))}
          </>
        )}
      </Container>
    </>
  );
};
