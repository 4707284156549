/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import type {
  ConversationInput,
  ConversationMessagePagination,
  ConversationMessagesConversationMessagesGetParams,
  ConversationStreamingResponse,
  GenerateResponseConversationPostParams,
} from './client.schemas'

const { REACT_APP_CHAT_API_URL } = process.env;

/**
 * @summary Default Route
 */
export type defaultRouteGetResponse = {
  data: string;
  status: number;
}

export const getDefaultRouteGetUrl = () => {


  return `${REACT_APP_CHAT_API_URL}/`
}

export const defaultRouteGet = async ( options?: RequestInit): Promise<defaultRouteGetResponse> => {
  const res = await fetch(
    getDefaultRouteGetUrl(),
    {      
      ...options,
      method: 'GET'
      
    }
  )
  const data = await res.json()
  
  return { status: res.status, data }
}


/**
 * @summary Return Version
 */
export type returnVersionVersionGetResponse = {
  data: string;
  status: number;
}

export const getReturnVersionVersionGetUrl = () => {


  return `${REACT_APP_CHAT_API_URL}/version`
}

export const returnVersionVersionGet = async ( options?: RequestInit): Promise<returnVersionVersionGetResponse> => {
  const res = await fetch(
    getReturnVersionVersionGetUrl(),
    {      
      ...options,
      method: 'GET'
      
    }
  )
  const data = await res.json()

  return { status: res.status, data }
}


/**
 * @summary Conversation Messages
 */
export type conversationMessagesConversationMessagesGetResponse = {
  data: ConversationMessagePagination;
  status: number;
}

export const getConversationMessagesConversationMessagesGetUrl = (params?: ConversationMessagesConversationMessagesGetParams,) => {

  const normalizedParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    if (value === null) {
      normalizedParams.append(key, 'null');
    } else if (value !== undefined) {
      normalizedParams.append(key, value.toString());
    }
  });

  return `${REACT_APP_CHAT_API_URL}/conversation_messages?${normalizedParams.toString()}`
}

export const conversationMessagesConversationMessagesGet = async (params?: ConversationMessagesConversationMessagesGetParams, options?: RequestInit): Promise<conversationMessagesConversationMessagesGetResponse> => {
  const res = await fetch(
    getConversationMessagesConversationMessagesGetUrl(params),
    {      
      ...options,
      method: 'GET'
      
    }
  )
  const data = await res.json()

  return { status: res.status, data }
}

/**
 * @summary Generate Response
 */
export type generateResponseConversationPostResponse = {
  data: ConversationStreamingResponse;
  status: number;
}

export const getGenerateResponseConversationPostUrl = (params?: GenerateResponseConversationPostParams,) => {

  const normalizedParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    if (value === null) {
      normalizedParams.append(key, 'null');
    } else if (value !== undefined) {
      normalizedParams.append(key, value.toString());
    }
  });

  return `${REACT_APP_CHAT_API_URL}/conversation?${normalizedParams.toString()}`
}

export const generateResponseConversationPost = async (conversationInput: ConversationInput,
    params?: GenerateResponseConversationPostParams, options?: RequestInit): Promise<generateResponseConversationPostResponse> => {
  const res = await fetch(
    getGenerateResponseConversationPostUrl(params),
    {      
      ...options,
      method: 'POST',
      body: JSON.stringify(
      conversationInput,)
    }
  )
  const data = await res.json()

  return { status: res.status, data }
}


/**
 * @summary Clear Conversation History
 */
export type clearConversationHistoryConversationClearPostResponse = {
  data: unknown;
  status: number;
}

export const getClearConversationHistoryConversationClearPostUrl = () => {


  return `${REACT_APP_CHAT_API_URL}/conversation/clear`
}

export const clearConversationHistoryConversationClearPost = async ( options?: RequestInit): Promise<clearConversationHistoryConversationClearPostResponse> => {
  const res = await fetch(
    getClearConversationHistoryConversationClearPostUrl(),
    {      
      ...options,
      method: 'POST'
      
    }
  )
  const data = await res.json()

  return { status: res.status, data }
}


