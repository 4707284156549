import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useClearConversation } from 'Assistant/Chatbot/hooks';
import Modal from 'Shared/Modal';
import { tracking } from 'App/Tracking';
import { useHistory } from 'react-router';
import RoutePath from 'App/RoutePath';
import {
  ChatClearErrorContainer,
  ChatClearErrorIcon,
  ChatClearErrorText,
  ChatClearBodyText,
  ChatClearTitleText,
  ChatClearButtonsContainer,
  ChatClearContentContainer,
  ClearButton,
  CancelButton,
  ClearButtonText,
} from './styles';

interface ClearChatModalProps {
  conversationId?: string;
  onClose(): void;
  redirectToSignIn(): void;
}

export const ClearChatModal = ({
  conversationId,
  onClose,
  redirectToSignIn,
}: ClearChatModalProps) => {
  const [show, setShow] = useState(true);
  const { t: translate } = useTranslation('chatbot');
  const history = useHistory();

  const handleModalClose = () => {
    tracking.track('ai-coach-chatbot-clear-conversation-cancel', {
      source: 'ai-coach-chatbot-ui',
      conversationId,
    });

    setShow(false);
    onClose();
  };

  const onSuccessCallback = () => {
    tracking.track('ai-coach-chatbot-clear-conversation-success', {
      source: 'ai-coach-chatbot-ui',
      conversationId,
    });

    setShow(false);
    onClose();
    history.push(RoutePath.ChatbotLanding);
  };

  const onAuthErrorCallback = async () => {
    setShow(false);
    redirectToSignIn();
  };

  const {
    isClearingConversation,
    hadErrorClearingConversation,
    clearConversation,
  } = useClearConversation({
    conversationId: conversationId,
    onSuccessCallback: onSuccessCallback,
    onAuthErrorCallback: onAuthErrorCallback,
  });

  return (
    <Modal
      open={show}
      onCloseCallback={handleModalClose}
      modalContent={() => (
        <>
          <ChatClearContentContainer>
            <ChatClearTitleText>
              {translate('clear_chat.title')}
            </ChatClearTitleText>
            <ChatClearBodyText>
              {translate('clear_chat.body')}
            </ChatClearBodyText>
          </ChatClearContentContainer>
          <ChatClearButtonsContainer>
            <ClearButton
              label={
                !hadErrorClearingConversation ? (
                  <ClearButtonText>
                    {translate('clear_chat.confirm_button.label')}
                  </ClearButtonText>
                ) : (
                  <ClearButtonText>
                    {translate('clear_chat.try_again_button.label')}
                  </ClearButtonText>
                )
              }
              onClick={() => {
                tracking.track(
                  `ai-coach-chatbot-clear-conversation${
                    hadErrorClearingConversation ? '-try-again' : ''
                  }`,
                  {
                    source: 'ai-coach-chatbot-ui',
                    conversationId,
                  },
                );
                void clearConversation();
              }}
              size="medium"
              loading={isClearingConversation}
            />
            {hadErrorClearingConversation && !isClearingConversation && (
              <ChatClearErrorContainer>
                <ChatClearErrorIcon />
                <ChatClearErrorText>
                  {translate('clear_chat.clearing_chat_error.body')}
                </ChatClearErrorText>
              </ChatClearErrorContainer>
            )}
            <CancelButton
              label={translate('clear_chat.cancel_button.label')}
              onClick={handleModalClose}
              size="medium"
            />
          </ChatClearButtonsContainer>
        </>
      )}
    />
  );
};
