import RoutePath from 'App/RoutePath';
import MediaPlayer from 'Series/SingleSeries/SeriesDay/SeriesSegment/Media/MediaPlayer';
import { useTimerDispatch } from 'Shared/Timer';
import React from 'react';
import { useHistory } from 'react-router';
import { usePlayerParams } from './usePlayerParams';

/* 
  Player that can play media in non authenticated context
  Used in MS Teams for opening deep links to media content when a user is not authenticated
*/

export const ExternalPlayer = () => {
  const context = useTimerDispatch();
  const history = useHistory();
  const { title, mediaSrc, mediaType } = usePlayerParams();

  return (
    <MediaPlayer
      onClose={() => history.replace(RoutePath.StartSplitScreen)}
      onClickReplay={() => null}
      onClickPlay={() => null}
      onClickPause={() => null}
      onNextStep={() => null}
      isLastSegmentInSeries={false}
      isLastSegmentInDay={false}
      isComplete={false}
      isTranscriptLoading={false}
      timerDispatch={context}
      segmentTitle={title}
      mediaSrc={mediaSrc}
      mediaType={mediaType}
      segmentId={''}
      seriesTitle={''}
      seriesSlug={''}
      segmentSlug={''}
      hasNextStep={false}
      showCalendarIntegration={false}
    />
  );
};
