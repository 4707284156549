import { gql, useLazyQuery } from '@apollo/client';
import { getMSTeamsUserContext, isMSTeams } from 'utils/MSTeams';
import { useEffect, useState } from 'react';
import { organisationsByMsTenantIds } from './__generated__/organisationsByMsTenantIds';
import { getOrganisationName } from './__generated__/getOrganisationName';
import { Organisation } from './SelectOrganisation';

export const QUERY_ORGANISATIONS_FROM_TENANT_ID = gql`
  query organisationsByMsTenantIds($input: OrganisationsByMsTenantIdsInput!) {
    organisationsByMsTenantIds(input: $input) {
      id
      subDomain
      msTenantId
    }
  }
`;

export const QUERY_ORG_NAME = gql`
  query getOrganisationName($subdomain: String!) {
    subDomain(subdomain: $subdomain) {
      name
    }
  }
`;

interface UseGetOrganisationsByTenantIds {
  onComplete(data: Organisation[]): void;
  skip?: boolean;
}

export const useGetOrganisationsByTenantIds = ({
  onComplete,
  skip = false,
}: UseGetOrganisationsByTenantIds) => {
  const [getOrganisationsByTenantIds] =
    useLazyQuery<organisationsByMsTenantIds>(
      QUERY_ORGANISATIONS_FROM_TENANT_ID,
    );

  const [getOrgName] = useLazyQuery<getOrganisationName>(QUERY_ORG_NAME);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchDetailedOrganisations = async (msTenantIds: string[]) => {
      setLoading(true);

      const { data, error } = await getOrganisationsByTenantIds({
        variables: { input: { msTenantIds } },
      });

      if (!data) {
        setLoading(false);

        return;
      }

      if (!data?.organisationsByMsTenantIds || error) {
        return;
      }

      const organisationsWithNames = await Promise.all(
        data.organisationsByMsTenantIds.map(async org => {
          const { data: nameData } = await getOrgName({
            variables: { subdomain: org?.subDomain },
          });

          const name = nameData?.subDomain?.[0]?.name;

          if (!name || !org?.id || !org?.subDomain) {
            return;
          }

          return {
            id: org?.id,
            primarySubDomain: org?.subDomain,
            name: name,
          };
        }),
      );

      onComplete(
        organisationsWithNames.filter(
          org => org !== undefined,
        ) as Organisation[],
      );
      setLoading(false);
    };

    const tenantId = getMSTeamsUserContext()?.user?.tenant?.id;
    const shouldFetch = isMSTeams() && tenantId && !skip;

    if (shouldFetch) {
      void fetchDetailedOrganisations([tenantId]);
    } else {
      setLoading(false);
    }
  }, [getOrganisationsByTenantIds, getOrgName, onComplete, skip]);

  return { loading };
};
