import React from 'react';
import { IconProps } from '../IconProps';
const SvgManagerSupport = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#ManagerSupport_svg__clip0_1781_32135)" fill="#200C05">
      <path d="M8 8.666a2.444 2.444 0 100-4.889 2.444 2.444 0 000 4.89zM12.125 12.872A4.244 4.244 0 008 9.555a4.244 4.244 0 00-4.124 3.317c-.168.768.31 1.554 1.088 1.789a10.49 10.49 0 006.073 0c.778-.236 1.256-1.021 1.088-1.79zM3.778 4.889a2 2 0 100-4 2 2 0 000 4zM5.35 8.908a3.761 3.761 0 01-1.128-2.686c0-.147.027-.287.044-.43-.117-.006-.233-.013-.36-.017l-.128-.003a3.847 3.847 0 00-3.733 2.9c-.2.784.294 1.61 1.098 1.843a8.571 8.571 0 002.128.359A5.616 5.616 0 015.35 8.908zM12.223 4.889a2 2 0 100-4 2 2 0 000 4zM10.65 8.908a3.761 3.761 0 001.128-2.686c0-.147-.027-.287-.044-.43.118-.006.233-.013.361-.017l.127-.003c1.764 0 3.3 1.192 3.734 2.9.199.784-.294 1.61-1.099 1.843a8.572 8.572 0 01-2.128.359 5.616 5.616 0 00-2.078-1.966z" />
    </g>
    <defs>
      <clipPath id="ManagerSupport_svg__clip0_1781_32135">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgManagerSupport;
