import { ThemeInterface } from '@unmind/design-system-theme';
import {
  LaptopUser,
  Legal,
  TabHelp,
  CareForOthers,
  FaceToFace,
  ManagerSupport,
} from 'icons';
import React from 'react';
import styled from 'styled-components';

const createHelpIcon = (IconComponent: React.ComponentType) =>
  styled(IconComponent).attrs(
    ({
      theme,
      height,
      width,
    }: {
      theme: ThemeInterface;
      height: number;
      width: number;
    }) => ({
      primaryColor: theme.colors.text.inverse,
      height,
      width,
      ['aria-hidden']: true,
    }),
  )``;

const SupportIcon = createHelpIcon(TabHelp);
const LaptopUserIcon = createHelpIcon(LaptopUser);
const LegalIcon = createHelpIcon(Legal);
const CareForOthersIcon = createHelpIcon(CareForOthers);
const FaceToFaceIcon = createHelpIcon(FaceToFace);
const ManagerSupportIcon = createHelpIcon(ManagerSupport);

const iconLookupMap: {
  [key: string]: ReturnType<typeof createHelpIcon> | undefined;
} = {
  'critical-incident': LaptopUserIcon,
  'face-to-face': FaceToFaceIcon,
  'financial-and-legal': LegalIcon,
  'immediate-support': SupportIcon,
  'manager-support': ManagerSupportIcon,
  'care-for-others': CareForOthersIcon,
  'under-18': LaptopUserIcon,
};

export const HelpIcon = ({
  icon,
  height = 16,
  width = 16,
}: {
  icon: string;
  height?: number;
  width?: number;
}) => {
  const Icon = iconLookupMap[icon] || SupportIcon;

  return <Icon height={height} width={width} />;
};
