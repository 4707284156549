import PrimaryButton from 'Shared/PrimaryButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SessionLink,
  SessionHeader,
  Session,
  CalendarIcon,
  SessionDescription,
} from './styles';

interface NextSessionLinkProps {
  title: string;
  link: string;
  sessionNumber: number;
}

export const NextSessionLink = ({
  title,
  link,
  sessionNumber,
}: NextSessionLinkProps) => {
  const { t: translate } = useTranslation('courses');

  return (
    <SessionLink>
      <SessionHeader>
        <Session>
          <CalendarIcon />
          {translate('course_day.session_number', {
            session_number: sessionNumber,
          })}
        </Session>
        <SessionDescription>{title}</SessionDescription>
      </SessionHeader>
      <PrimaryButton
        to={link}
        label={translate('course_day.cta_button.continue_variant.text')}
      />
    </SessionLink>
  );
};
